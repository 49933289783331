import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import Code from '@primer/gatsby-theme-doctocat/src/components/code';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`ThemeProvider`}</h2>
    <p>{`To give components access to the theme object, you must add `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{` to the root of your application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {ThemeProvider} from '@primer/react'

function App() {
  return (
    <ThemeProvider>
      <div>...</div>
    </ThemeProvider>
  )
}
`}</code></pre>
    <p><inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{` comes with a `}<a parentName="p" {...{
        "href": "/guides/react/theme-reference"
      }}>{`default theme object`}</a>{` that includes colors, spacing, fonts, etc. for building applications at GitHub.`}</p>
    <h2>{`Customizing the theme`}</h2>
    <p>{`To customize the `}<a parentName="p" {...{
        "href": "/guides/react/theme-reference"
      }}>{`default theme`}</a>{`, you can pass your custom theme to `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{` using the `}<inlineCode parentName="p">{`theme`}</inlineCode>{` prop:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {ThemeProvider, theme} from '@primer/react'
import deepmerge from 'deepmerge'

const customTheme = deepmerge(theme, {
  fonts: {
    mono: 'MonoLisa, monospace',
  },
})

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <div>...</div>
    </ThemeProvider>
  )
}
`}</code></pre>
    <p>{`Some components may break if your custom theme does not include all the same keys as the `}<a parentName="p" {...{
        "href": "/guides/react/theme-reference"
      }}>{`default theme`}</a>{`. For that reason, we recommend extending the default theme using `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/deepmerge"
      }}>{`deepmerge`}</a>{`.`}</p>
    <h2>{`Referencing theme values`}</h2>
    <p>{`You can reference theme values in your application using the `}<a parentName="p" {...{
        "href": "/overriding-styles"
      }}><inlineCode parentName="a">{`sx`}</inlineCode>{` prop`}</a>{`, the `}<inlineCode parentName="p">{`themeGet`}</inlineCode>{` function, or the `}<inlineCode parentName="p">{`useTheme`}</inlineCode>{` hook.`}</p>
    <p>{`Only use `}<inlineCode parentName="p">{`theme`}</inlineCode>{` objects accessed via Primer's theme context to ensure your application’s styling draws from the same theme as Primer components’ internal styling. The `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop, styled system props, `}<inlineCode parentName="p">{`themeGet`}</inlineCode>{`, and `}<inlineCode parentName="p">{`useTheme`}</inlineCode>{` all use the theme from context.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <Code className="language-jsx" mdxType="Code">
      {`<Box textShadow="shadow.medium">`}
    </Code>
    <Caption mdxType="Caption">Use the theme from the same context as Primer.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <Code className="language-jsx" mdxType="Code">
      {`import {theme} from '@primer/react'\n\n<Box textShadow={theme.shadows.shadow.medium}>`}
    </Code>
    <Caption mdxType="Caption">Don't style components with any other instance of theme</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`System props and the `}<inlineCode parentName="h3">{`sx`}</inlineCode>{` prop`}</h3>
    <p>{`Some `}<a parentName="p" {...{
        "href": "/guides/react/system-props"
      }}>{`system props`}</a>{` and `}<a parentName="p" {...{
        "href": "/guides/react/overriding-styles"
      }}><inlineCode parentName="a">{`sx`}</inlineCode>{` prop`}</a>{` keys are theme-aware. For example, the `}<inlineCode parentName="p">{`bg`}</inlineCode>{` prop maps to the `}<inlineCode parentName="p">{`colors`}</inlineCode>{` theme key which means you can use the `}<inlineCode parentName="p">{`bg`}</inlineCode>{` prop to reference values in the `}<inlineCode parentName="p">{`colors`}</inlineCode>{` object:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const theme = {
  colors: {
    canvas: {
      default: '#fff',
    },
  },
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box bg="canvas.default"></Box>
      <Box sx={{bg: 'canvas.default'}}></Box>
    </ThemeProvider>
  )
}
`}</code></pre>
    <p>{`See the `}<a parentName="p" {...{
        "href": "https://styled-system.com/table"
      }}>{`Styled System Reference Table`}</a>{` for a complete list of mappings.`}</p>
    <h3>{`themeGet`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`themeGet`}</inlineCode>{` function is a convenient way to reference theme values in styled-components template literals:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {themeGet} from '@primer/react'
import styled from 'styled-components'

const Example = styled.div\`
  background-color: \${themeGet('colors.canvas.default')};
\`
`}</code></pre>
    <h3>{`useTheme`}</h3>
    <p>{`You can use the `}<inlineCode parentName="p">{`useTheme`}</inlineCode>{` hook to reference theme values from inside any function component nested under the `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {ThemeProvider, useTheme} from '@primer/react'

function Example() {
  const {theme} = useTheme()
  // theme.colors.canvas.default
}

function App() {
  return (
    <ThemeProvider>
      <Example />
    </ThemeProvider>
  )
}
`}</code></pre>
    <Note variant="warning" mdxType="Note">
      <p>{`Only use `}<inlineCode parentName="p">{`useTheme`}</inlineCode>{` to reference theme values in places where it's not possible to use system props, the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop, or `}<inlineCode parentName="p">{`themeGet`}</inlineCode>{`.`}</p>
    </Note>
    <h2>{`Color modes and color schemes`}</h2>
    <p>{`The terms "color mode" and "color scheme" are often used interchangeably. However, in Primer React, they are two separate (but related) concepts.`}</p>
    <p>{`The "color mode" of an application can be either `}<inlineCode parentName="p">{`day`}</inlineCode>{`, `}<inlineCode parentName="p">{`night`}</inlineCode>{`, or `}<inlineCode parentName="p">{`auto`}</inlineCode>{` (i.e. synced with the operating system).`}</p>
    <p>{`A "color scheme", on the other hand, is a collection of colors that can be associated with a color mode. The `}<a parentName="p" {...{
        "href": "/guides/react/theme-reference"
      }}>{`default theme`}</a>{` includes several color schemes, including `}<inlineCode parentName="p">{`light`}</inlineCode>{`, `}<inlineCode parentName="p">{`dark`}</inlineCode>{`, and `}<inlineCode parentName="p">{`dark_dimmed`}</inlineCode>{`. By default, the `}<inlineCode parentName="p">{`light`}</inlineCode>{` scheme is displayed when the application is in `}<inlineCode parentName="p">{`day`}</inlineCode>{` mode and the `}<inlineCode parentName="p">{`dark`}</inlineCode>{` scheme is displayed in `}<inlineCode parentName="p">{`night`}</inlineCode>{` mode.`}</p>
    <h3>{`Setting the color mode`}</h3>
    <p>{`By default, Primer React is in `}<inlineCode parentName="p">{`day`}</inlineCode>{` mode. To change the color mode, use the `}<inlineCode parentName="p">{`colorMode`}</inlineCode>{` prop on `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{` or the `}<inlineCode parentName="p">{`setColorMode`}</inlineCode>{` function from the `}<inlineCode parentName="p">{`useTheme`}</inlineCode>{` hook:`}</p>
    <h4><inlineCode parentName="h4">{`colorMode`}</inlineCode>{` prop`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {ThemeProvider} from '@primer/react'

function App() {
  return (
    // colorMode can be "day" (default), "night", or "auto"
    <ThemeProvider colorMode="auto">
      <div>...</div>
    </ThemeProvider>
  )
}
`}</code></pre>
    <h4><inlineCode parentName="h4">{`setColorMode`}</inlineCode>{` function`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {useTheme} from '@primer/react'

function Example() {
  const {setColorMode} = useTheme()
  return <button onClick={() => setColorMode('auto')}>Activate auto mode</button>
}
`}</code></pre>
    <h4><inlineCode parentName="h4">{`preventSSRMismatch`}</inlineCode>{` prop`}</h4>
    <p>{`If you are doing server-side rendering, pass the `}<inlineCode parentName="p">{`preventSSRMismatch`}</inlineCode>{` prop to ensure the rendered output from the server and browser match even when they resolve "auto" color mode differently.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ThemeProvider colorMode="auto" preventSSRMismatch>
  ...
</ThemeProvider>
`}</code></pre>
    <h3>{`Setting color schemes`}</h3>
    <p>{`To choose which color schemes will be displayed in `}<inlineCode parentName="p">{`day`}</inlineCode>{` and `}<inlineCode parentName="p">{`night`}</inlineCode>{` mode, use the `}<inlineCode parentName="p">{`dayScheme`}</inlineCode>{` and `}<inlineCode parentName="p">{`nightScheme`}</inlineCode>{` props on `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{` or the `}<inlineCode parentName="p">{`setDayScheme`}</inlineCode>{` and `}<inlineCode parentName="p">{`setNightScheme`}</inlineCode>{` functions from the `}<inlineCode parentName="p">{`useTheme`}</inlineCode>{` hook:`}</p>
    <h4><inlineCode parentName="h4">{`dayScheme`}</inlineCode>{` and `}<inlineCode parentName="h4">{`nightScheme`}</inlineCode>{` props`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {ThemeProvider} from '@primer/react'

function App() {
  return (
    // The default theme includes \`light\`, \`dark\`, and \`dark_dimmed\` schemes
    <ThemeProvider dayScheme="light" nightScheme="dark_dimmed">
      <div>...</div>
    </ThemeProvider>
  )
}
`}</code></pre>
    <h4><inlineCode parentName="h4">{`setDayScheme`}</inlineCode>{` and `}<inlineCode parentName="h4">{`setNightScheme`}</inlineCode>{` functions`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {useTheme} from '@primer/react'

function Example() {
  const {setDayScheme, setNightScheme} = useTheme()
  return <button onClick={() => setNightScheme('auto')}>Activate auto mode</button>
}
`}</code></pre>
    <h3>{`Customizing or adding color schemes`}</h3>
    <p>{`To customize or add color schemes, update the `}<inlineCode parentName="p">{`colorSchemes`}</inlineCode>{` object in the theme:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {ThemeProvider, theme} from '@primer/react'
import deepmerge from 'deepmerge'

const customTheme = deepmerge(theme, {
  colorSchemes: {
    // Customize an existing scheme
    light: {
      colors: {
        text: {
          primary: '#f00',
        },
      },
    },
    // Add a new scheme
    my_scheme_name: {
      colors: {},
      shadows: {},
    },
  },
})

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <div>...</div>
    </ThemeProvider>
  )
}
`}</code></pre>
    <h3>{`Creating local color scheme variables`}</h3>
    <p>{`If you need to use a color that is not defined in the theme, avoid hard coding the color value like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Example() {
  return (
    // BAD: #aaa may not look good in all color schemes
    <Box bg="#aaa">Hello world</Box>
  )
}
`}</code></pre>
    <p>{`Instead, use the `}<inlineCode parentName="p">{`useColorSchemeVar`}</inlineCode>{` hook to create a local variable that will update based on the active color scheme:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {useColorSchemeVar} from '@primer/react'
import {colors} from '@primer/primitives'

function Example() {
  // GOOD: The value of \`customBg\` changes based on the active color scheme
  const customBg = useColorSchemeVar({
    light: colors.light.scale.gray[1],
    dark: colors.dark.scale.gray[9],
    dark_dimmed: colors.dark_dimmed.scale.gray[2],
  })
  return <Box bg={customBg}>Hello world</Box>
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      